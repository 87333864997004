<template>
  <div class="service-table">
    <div class="service-table__header">
      <div class="service-table__header_button">
        <router-link :to="{ path: 'register' }">
          <el-button type="primary" style="margin-right: 10px">注册空间服务 </el-button>
        </router-link>
        <el-button @click="handleMultipleDelete" :disabled="!multipleSelection.length">批量删除</el-button>
        <el-button @click="handleMoveDialog" :disabled="!multipleSelection.length">批量移动</el-button>
      </div>
      <el-input placeholder="请输入服务名称" @input="filterSpaceService" v-model="searchProps.keyword"></el-input>
    </div>
    <div class="service-table__content">
      <list-wrap :loading="tableState.loading" :empty="tableState.tableData.length === 0" :handleCreate="handleCreate">
        <el-table :data="tableState.tableData" style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection"></el-table-column>
          <el-table-column label="服务名称" sortable prop="serviceName"></el-table-column>
          <el-table-column label="类型" sortable prop="serviceTypeName"></el-table-column>
          <el-table-column label="状态">
            <template #default="scope">
              <div class="status-content">
                <span class="status-content__border" :style="{ background: statusColor[scope.row.status] }"></span>
                <span :style="{ color: statusColor[scope.row.status] }">{{ statusLabel[scope.row.status] }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" sortable prop="createTime"> </el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-button type="text" @click="handleRemove(scope.row)">删除</el-button>
              <el-button type="text" @click="handleOneMoveDialog(scope.row.id)">移动</el-button>
              <el-button type="text" @click="handleCheckDetail(scope.row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <packaged-pagination
          v-if="tableState.tableData.length && !tableState.loading"
          :current-page="searchProps.pageNo"
          :page-size="searchProps.pageSize"
          :total="tableState.total"
          @size-change="handlePageSizeChange"
          @current-change="handlePageChange"
        ></packaged-pagination>
      </list-wrap>
    </div>
    <move-service-dialog
      ref="moveDialogRef"
      @success-move="handleSuccessMove"
      @close-move-dialog="
        () => {
          $emit('close-move-dialog');
        }
      "
    />
  </div>
</template>

<script>
import { reactive, ref, watch } from 'vue';
import { debounce } from 'lodash';
import { useRouter } from 'vue-router';
import MoveServiceDialog from './MoveServiceDialog';
import { ElMessage, ElMessageBox } from 'element-plus';
import { deleteSpaceService, getSpaceServiceList, multipleDeleteService } from '../../../../api/cim';

export default {
  name: 'ServiceList',
  components: { MoveServiceDialog },
  props: {
    currentCategory: {
      type: [Number, String],
      default: null,
    },
  },
  setup(props) {
    const tableState = reactive({
      tableData: [],
      total: 0,
      loading: false,
    });
    const searchProps = reactive({
      pageNo: 1,
      pageSize: 10,
      keyword: '',
    });

    // 跳转至注册空间服务
    const router = useRouter();
    const handleCreate = () => {
      router.push({ path: 'register' });
    };

    // 获取服务列表
    const fetchSpaceServiceList = async () => {
      try {
        tableState.loading = true;
        const { data } = await getSpaceServiceList({ ...searchProps, ...{ catalogId: props.currentCategory } });
        tableState.tableData = data.list || [];
        tableState.total = data.total;
      } catch (e) {
        ElMessage.error('获取服务列表失败');
      }
      tableState.loading = false;
    };
    fetchSpaceServiceList();

    // 输入框模糊查询列表
    const filterSpaceService = debounce(() => {
      searchProps.pageNo = 1;
      fetchSpaceServiceList();
    }, 500);

    // 选中目录时获取目录对应的服务列表
    watch(
      () => [props.currentCategory],
      () => {
        searchProps.pageNo = 1;
        fetchSpaceServiceList();
      },
    );

    const handlePageSizeChange = (pageSize) => {
      searchProps.pageSize = pageSize;
      fetchSpaceServiceList();
    };

    const handlePageChange = (page) => {
      searchProps.pageNo = page;
      fetchSpaceServiceList();
    };

    // handle列表多选及删除
    const multipleSelection = ref([]);
    const handleSelectionChange = (val) => {
      multipleSelection.value = val;
    };
    const handleMultipleDelete = () => {
      ElMessageBox.confirm(`是否删除选中服务?`, '提示', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const { code } = await multipleDeleteService({
            ids: multipleSelection.value.map((item) => item.id).join(','),
          });
          if (code === 0) {
            ElMessage.success('当前服务已成功删除');
          } else {
            ElMessage.error('服务删除失败');
          }
          await fetchSpaceServiceList();
        })
        .catch((e) => {
          console.log(e);
        });
    };

    // 删除单个服务
    const handleRemove = (row) => {
      ElMessageBox.confirm(`确认删除服务${row.serviceName}吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const { code } = await deleteSpaceService(row.id);
          if (code === 0) {
            ElMessage.success('当前服务已成功删除');
          } else {
            ElMessage.error('服务删除失败');
          }
          if (tableState.tableData.length === 1) {
            searchProps.pageNo = searchProps.pageNo >= 2 ? searchProps.pageNo - 1 : 1;
          }
          await fetchSpaceServiceList();
        })
        .catch((e) => {
          console.log(e);
        });
    };

    // 查看当前服务详情
    const handleCheckDetail = (row) => {
      // router.push({ path: `space/detail/${row.id}` });
      const newPath = router.resolve(`space/detail/${row.id}`);
      window.open(newPath.href);
    };

    // 打开移动弹窗相关
    const moveDialogRef = ref();
    const handleMoveDialog = () => {
      const ids = multipleSelection.value.map((item) => item.id).join(',');
      moveDialogRef.value.handleOpen(ids);
    };

    const handleOneMoveDialog = (id) => {
      moveDialogRef.value.handleOpen(id);
    };

    // 成功移动目录
    const handleSuccessMove = () => {
      fetchSpaceServiceList();
    };

    // 状态相关映射
    const statusColor = {
      1: '#0ABF5B',
      2: '#D7D7D7',
    };
    const statusLabel = {
      1: '在线',
      2: '离线',
    };
    return {
      tableState,
      searchProps,
      handlePageSizeChange,
      handlePageChange,
      handleCreate,
      handleSelectionChange,
      moveDialogRef,
      handleMoveDialog,
      fetchSpaceServiceList,
      filterSpaceService,
      handleRemove,
      handleCheckDetail,
      handleMultipleDelete,
      handleSuccessMove,
      statusColor,
      statusLabel,
      multipleSelection,
      handleOneMoveDialog,
    };
  },
};
</script>

<style lang="scss" scoped>
.service-table {
  margin: 15px 0 0 40px;
  .service-table__header {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    :deep(.el-input.el-input--small) {
      width: 300px;
    }
  }
  :deep(.service-table__content) {
    .status-content {
      display: inline-flex;
      align-items: center;
      .status-content__border {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: transparent;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
  }
}
</style>
