<template>
  <div class="service-manu__header" v-if="header">
    <h2>目录树</h2>
    <div class="solid-line"></div>
  </div>
  <div class="service-manu__content">
    <el-tree
      :data="categoryTree"
      node-key="id"
      :props="{
        children: 'child',
      }"
      default-expand-all
      :expand-on-click-node="false"
      :current-node-key="selectedNode"
      @node-click="getCurrentNode"
      class="service-manu__tree"
      highlight-current
      ref="treeRef"
    >
      <template #default="{ node, data }">
        <span class="custom-tree-node">
          <EditableTextField
            v-model:value="data.name"
            :editable="data._editable"
            @node-edit="
              () => {
                handleNodeEdit(node, data);
              }
            "
          ></EditableTextField>
          <span class="actions" v-show="editable">
            <div class="actions_item" @click="enableEditing(data)">
              <svg-icon icon-name="edit" />
            </div>
            <div class="actions_item" @click="handleNodeRemove(data)" v-if="data.specialTag === 0">
              <svg-icon icon-name="minus-circle" />
            </div>
            <el-dropdown
              trigger="click"
              class="actions_item dropdown"
              @command="handleAppendCommand(node, data, $event)"
            >
              <svg-icon icon-name="plus-circle" />
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="sameLevel"> 同级目录 </el-dropdown-item>
                  <el-dropdown-item command="child"> 子级目录 </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>

            <div class="actions_item move-up__btn" @click="moveup(node, data)"><span class="el-icon-top"></span></div>
            <div class="actions_item move-down__btn" @click="movedown(node, data)">
              <span class="el-icon-bottom"></span>
            </div>
          </span>
        </span>
      </template>
    </el-tree>
    <move-service-dialog @success-move="handleSuccessMove" />
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import EditableTextField from './EditableTextField.vue';
import { deleteCategory, updateCategory, addCategory, getCategoryTree, moveCatalogItem } from '@/api/cim';
import { ElMessage, ElMessageBox } from 'element-plus';
import MoveServiceDialog from './MoveServiceDialog';
const getId = () => Math.random().toString(36).slice(2);
const getNode = (data) => {
  const NEW_NODE_DATA = {
    id: getId(),
    name: '',
    child: [],
  };
  return {
    ...NEW_NODE_DATA,
    ...data,
  };
};

export default {
  name: 'ServiceTree',
  components: { MoveServiceDialog, EditableTextField },
  props: {
    currentCategory: {
      type: [Number, String],
      default: null,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    header: {
      type: Boolean,
      default: true,
    },
    delayUpdate: {
      type: Boolean,
      default: false,
    },
    onCancel: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['current-change'],

  setup(props, { emit }) {
    const selectedNode = ref(props.currentCategory);
    const treeRef = ref();
    watch(
      () => props.currentCategory,
      () => {
        selectedNode.value = props.currentCategory;
      },
    );

    // 获取目录树数据
    const categoryTree = ref([]);
    const fetchServiceCateGory = async () => {
      try {
        const { data } = await getCategoryTree();
        categoryTree.value = data;
      } catch (e) {
        console.log(e);
      }
    };
    fetchServiceCateGory();
    // 服务列表成功移动时刷新服务树
    const handleSuccessMove = () => {
      fetchServiceCateGory();
    };
    // 选中节点时向上层发送数据
    const getCurrentNode = (node) => {
      selectedNode.value = node.id;
      if (!node.isNew) {
        emit('current-change', node.id, node);
      }
    };

    // 添加节点
    const handleAppendCommand = async (node, data, command) => {
      const { parent } = node;
      const newNode = getNode({ name: '新建目录', _editable: true, isNew: true });
      if (command === 'child') {
        node.data.child.push(newNode);
      } else {
        if (parent.parent === null) {
          // root
          parent.data.push(newNode);
        } else {
          parent.data.child.push(newNode);
        }
      }
      categoryTree.value = [...categoryTree.value];
    };

    // 当前节点进行的操作类型
    const serviceOption = ref('append');
    const dialogRef = ref();

    // 编辑当前节点
    const editOrAppend = async (node, data) => {
      const { parent } = node;
      const params = {
        parentId: parent.data?.id ?? 0,
        id: data.isNew ? null : data.id,
        name: data.name,
      };
      const api = data.isNew ? addCategory : updateCategory;
      try {
        const { code } = await api({
          ...params,
        });
        if (code === 0) {
          ElMessage.success('成功更新目录');
        } else {
          ElMessage.error('目录更新失败');
        }
      } catch (e) {
        console.log(e);
      }
      await fetchServiceCateGory();
      selectedNode.value = null;
    };

    const handleNodeEdit = (node, data) => {
      if (props.delayUpdate) {
        setTimeout(() => {
          if (props.onCancel) {
            fetchServiceCateGory();
          } else {
            editOrAppend(node, data);
          }
        }, 100);
      } else {
        editOrAppend(node, data);
      }
    };

    const enableEditing = (data) => {
      // eslint-disable-next-line no-param-reassign
      data._editable = true;
    };

    // 移动目录时 若选中某个节点则向其子级添加，否则默认向根目录添加
    const appendToRoot = (data = getNode({ name: '新建文件夹', _editable: true, isNew: true })) => {
      if (!selectedNode.value) {
        categoryTree.value.push(data);
      } else {
        const node = treeRef.value.getCurrentNode();
        node.child.push(data);
      }
      categoryTree.value = [...categoryTree.value];
    };

    const onInputName = (event, data) => {
      // eslint-disable-next-line no-param-reassign
      data.name = event.target.innerText;
    };

    // 删除目录
    const handleNodeRemove = (data) => {
      ElMessageBox.confirm(`确认删除目录${data?.name}吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const { code } = await deleteCategory(data.id);
          if (code === 0) {
            ElMessage.success('当前目录已成功删除');
          } else {
            ElMessage.error('目录删除失败');
          }
          await fetchServiceCateGory();
        })
        .catch((e) => {
          console.log(e);
        });
    };

    // move item

    const moveItem = async (node, data, step) => {
      try {
        const { parent } = node;
        let child;
        if (parent.parent === null) {
          child = parent.data;
        } else {
          child = parent.data.child;
        }
        const index = child.findIndex((item) => item.id === data.id);

        if (index === 0 && step === -1) {
          ElMessage.warning('已经是第一个了');
          return;
        }
        if (index === child.length - 1 && step === 1) {
          ElMessage.warning('已经是最后一个了');
          return;
        }
        await moveCatalogItem({
          catalogMoveInfo: [
            {
              id: child[index + step].id,
              sort: data.sort,
            },
            {
              id: data.id,
              sort: child[index + step]?.sort,
            },
          ],
        });
        fetchServiceCateGory(); // 刷新列表
      } catch (error) {
        console.log(error);
        ElMessage.error('移动失败');
      }
    };
    const moveup = async (node, data) => {
      moveItem(node, data, -1);
    };
    const movedown = (node, data) => {
      moveItem(node, data, 1);
    };

    return {
      dialogRef,
      handleNodeEdit,
      handleNodeRemove,
      handleAppendCommand,
      serviceOption,
      appendToRoot,
      onInputName,
      enableEditing,
      getCurrentNode,
      selectedNode,
      categoryTree,
      fetchServiceCateGory,
      treeRef,
      handleSuccessMove,
      movedown,
      moveup,
    };
  },
};
</script>

<style lang="scss" scoped>
.service-manu__header {
  .solid-line {
    height: 1px;
    margin: 20px 0;
    background: #dcdfe6;
  }
}
:deep(.custom-tree-node) {
  flex: 1;
  display: flex;
  align-items: center;
  .actions {
    display: none;
    &_item {
      font-size: 16px;
      color: #7e868e;
      padding-left: 10px;
      .svg-icon {
        vertical-align: middle;
        padding: 0;
      }
      &:hover {
        color: #006eff;
      }
    }
    .dropdown {
      position: relative;
      top: 1px;
      font-size: 16px;
    }
  }
  &:hover {
    .actions {
      display: inline-flex;
      align-items: center;
      vertical-align: middle;
      margin-left: 20px;
    }
  }
}
.service-manu__tree {
  :deep(.el-tree-node__content) {
    height: 36px;
  }
}
.move-up__btn,
.move-down__btn {
  > span {
    vertical-align: middle;
  }
}
</style>
