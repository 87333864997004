import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-272bb31f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field__container" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    (_ctx.localEditable)
      ? (_openBlock(), _createBlock(_component_el_input, {
          key: 0,
          modelValue: _ctx.localValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
          onBlur: _ctx.onBlur,
          size: "small",
          ref: "inputRef"
        }, null, 8, ["modelValue", "onBlur"]))
      : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.localValue), 1))
  ]))
}