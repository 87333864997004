<template>
  <el-dialog title="移动到" v-model="visible" @close="handleClose" width="400px">
    <div class="main">
      <ServiceTree
        :editable="false"
        :header="header"
        :onCancel="onCancel"
        :delayUpdate="true"
        ref="treeRef"
        @current-change="handleCurrentNode"
      />
    </div>
    <div class="footer">
      <div class="footer-new">
        <a class="footer-new__icon" @click="createCatalog">
          <svg-icon icon-name="plus-circle" />
        </a>
        <span class="footer-new__text">新建文件夹</span>
      </div>
      <div class="footer-button">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleMoveService">确定</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import ServiceTree from './ServiceTree.vue';
import { ref } from 'vue';
import { moveSpaceService } from '../../../../api/cim';
import { ElMessage } from 'element-plus';

export default {
  name: 'MoveServiceDialog',
  emits: ['success-move', 'close-move-dialog'],
  components: { ServiceTree },
  setup(props, { emit }) {
    const visible = ref(false);
    const currentServiceId = ref();
    const currentCategoryId = ref();
    const treeRef = ref();
    const header = ref(false);
    const onCancel = ref(false);
    // 打开移动弹窗并接收当前服务Id
    const handleOpen = (val) => {
      visible.value = true;
      onCancel.value = false;
      currentServiceId.value = val;
      setTimeout(() => {
        treeRef.value.fetchServiceCateGory();
      }, 100);
    };
    const handleClose = () => {
      visible.value = false;
      onCancel.value = true;
      emit('close-move-dialog');
    };

    // 获取当前目录id
    const handleCurrentNode = (val) => {
      currentCategoryId.value = val;
    };
    const handleMoveService = async () => {
      try {
        const { code } = await moveSpaceService({
          serviceIds: currentServiceId.value,
          catalogId: currentCategoryId.value,
        });
        if (code === 0) {
          emit('success-move');
          ElMessage.success('成功移动目录');
        } else {
          ElMessage.error('移动目录失败');
        }
      } catch (e) {
        console.log(e);
      }
      handleClose();
    };

    const createCatalog = () => {
      treeRef.value.appendToRoot();
    };
    return {
      visible,
      header,
      handleOpen,
      handleClose,
      onCancel,
      currentCategoryId,
      handleMoveService,
      treeRef,
      createCatalog,
      handleCurrentNode,
    };
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  .svg-icon {
    vertical-align: middle;
    position: relative;
    top: -2px;
    font-size: 18px;
    color: #006eff;
  }
  .footer-new__text {
    display: inline-block;
    margin-left: 5px;
    font-size: 14px;
    color: #006eff;
  }
}
</style>
