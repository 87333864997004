<template>
  <div class="service-manage" style="background: #fff; padding: 30px">
    <div class="service-manage__tree">
      <service-tree :current-category="currentCategory" @current-change="handleCurrentChange" ref="serviceTree" />
    </div>
    <div class="service-manage__table">
      <service-list :current-category="currentCategory" @close-move-dialog="onCloseMoveDialog" />
    </div>
  </div>
</template>
<script>
import ServiceList from './components/ServiceList';
import ServiceTree from './components/ServiceTree';
import { ref } from 'vue';
export default {
  components: { ServiceTree, ServiceList },
  setup() {
    const currentCategory = ref();
    const serviceTree = ref();
    const handleCurrentChange = (nodeId) => {
      currentCategory.value = nodeId;
    };
    const onCloseMoveDialog = () => {
      serviceTree.value.fetchServiceCateGory();
    };
    return {
      currentCategory,
      handleCurrentChange,
      serviceTree,
      onCloseMoveDialog,
    };
  },
};
</script>

<style lang="scss" scoped>
.service-manage {
  display: flex;
  .service-manage__tree {
    width: 30%;
  }
  .service-manage__table {
    width: 70%;
    margin-left: 40px;
  }
}
</style>
