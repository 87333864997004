
import { ElInput } from 'element-plus';
import { computed, defineComponent, PropType, ref, watch, watchEffect } from 'vue';

export default defineComponent({
  name: 'EditTextField',
  props: {
    value: {
      type: String as PropType<string>,
      default: '',
    },
    editable: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  emits: ['node-edit', 'update:value'],
  setup(props, { emit }) {
    const localValue = computed({
      get() {
        return props.value;
      },
      set(v) {
        emit('update:value', v);
      },
    });

    const localEditable = ref(props.editable);
    const inputRef = ref<InstanceType<typeof ElInput>>();
    watch(
      () => props.editable,
      (v) => {
        localEditable.value = v;
      },
    );
    watchEffect(() => {
      if (localEditable.value && inputRef.value) {
        inputRef.value.focus();
      }
    });
    const onBlur = () => {
      localEditable.value = false;
      emit('node-edit');
    };
    return {
      localValue,
      localEditable,
      onBlur,
      inputRef,
    };
  },
});
